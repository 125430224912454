import { IStorageService } from './IStorageService';
import { StorageService } from './storageService';

export class StorageServiceBuilder {
    private static _instance: IStorageService = null;

    public static Instance(): IStorageService {
        if (this._instance == null) {
            this._instance = new StorageService();
        }

        return this._instance;
    }
}
