import { IStorageService } from './IStorageService';
import { DataValidationServiceBuilder } from '../DataValidation/dataValidationServiceBuilder';

export class StorageService implements IStorageService {
    private validateKeySuffix = '.validate';

    public save(key: string, data: string) {
        this._save(key, data);

        const hash = DataValidationServiceBuilder.Instance().generateHash(data);
        this._save(key + this.validateKeySuffix, hash);
    }

    public load(key: string): string|null {
        const content = this._load(key);

        if (content) {
            const hash = this._load(key + this.validateKeySuffix);
            const check = DataValidationServiceBuilder.Instance().checkHash(content, hash);

            if (check) {
                return content;
            }
        }

        return null;
    }

    private _save(key: string, data: string) {
        localStorage.setItem(key, data);
    }
    private _load(key): string {
        return localStorage.getItem(key);
    }
}
