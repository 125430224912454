import { AxiosPromise } from 'axios';
import API from './api';

import * as Customer from '../../model/customer';
import * as CustomerToken from '../../model/customerLogin';
import * as Shops from '../../model/shops';
import * as Shop from '../../model/shop';
import * as OrderList from '../../model/orderList';
import * as Takeout from '../../model/takeout';
import * as TakeoutCheck from '../../model/takeoutCheck';
import * as Setting from '../../model/setting';
import * as CustomerCard from '../../model/customerCard';
import * as CustomerDeleteCard from '../../model/customerDeleteCard';
import * as CustomerHistories from '../../model/customerHistories';
import * as RePasswordEmail from '../../model/rePasswordEmail';
import * as RePassword from '../../model/rePassword';

class TakeoutAPI extends API {
  public set_token(token: string) {
    if (token) {
      this.headers.Authorization = `Bearer ${token}`;
    } else {
      this.headers.Authorization = '';
    }
  }

  public customer_get_account(): AxiosPromise<Customer.Response> {
    return super.request('/customer/accounts', 'GET', null, null);
  }
  public customer_account(req: Customer.Request, query: Customer.RequestQuery): AxiosPromise<Customer.Response> {
    return super.request('/customer/accounts', 'POST', req, query);
  };
  public customer_update_account(req: Customer.Request, query: Customer.RequestQuery): AxiosPromise<Customer.Response> {
    return super.request('/customer/accounts', 'PUT', req, query);
  }
  public customer_token(req: CustomerToken.Request, query: Customer.RequestQuery): AxiosPromise<CustomerToken.Response> {
    return super.request('/customer/accounts/token', 'POST', req, query);
  };
  public customr_cards(): AxiosPromise<CustomerCard.Response> {
    return super.request('/customer/cards', 'GET', null, null);
  };
  public customer_hisotries(query: CustomerHistories.RequestQuery): AxiosPromise<CustomerHistories.Response> {
    return super.request('/customer/histories', 'GET', null, query);
  };
  public customer_delete_card(req: CustomerDeleteCard.Request): AxiosPromise<CustomerDeleteCard.Response> {
    return super.request(`/customer/cards/${req.payment_method_id}`, 'DELETE', req, null);
  };

  public re_password(req: RePassword.Request, query: RePassword.RequestQuery): AxiosPromise<RePassword.Response> {
    return super.request(`/re-password/edit/${query.re_password_token_id}`, 'POST', req, query);
  }
  public re_password_email(req: RePasswordEmail.Request, query: RePasswordEmail.RequestQuery): AxiosPromise<RePasswordEmail.Response> {
    return super.request('/re-password/email', 'POST', req, query);
  }

  public shops(query: Shops.RequestQuery): AxiosPromise<Shops.Respons> {
    return super.request('/shops', 'GET', null, query);
  };
  public shop(query: Shop.RequestQuery): AxiosPromise<Shop.Respons> {
    return super.request(`/shop/${query.shop_id}`, 'GET', null, query);
  };

  public orderList(req: OrderList.Request, query: OrderList.RequestQuery): AxiosPromise<OrderList.Respons> {
    return super.request(`/orders/list/${query.shop_id}`, 'POST', req, query);
  }

  public takeout(res: Takeout.Requet, query: Takeout.RequestQuery): AxiosPromise<Takeout.Respons> {
    return super.request('/takeout', 'POST', res, query);
  }
  public takeout_check(query: TakeoutCheck.RequestQuery): AxiosPromise<TakeoutCheck.Respons> {
    return super.request(`/takeout/${query.payment_intent_id}`, 'GET', null, query);
  }

  public setting(query: Setting.RequestQuery): AxiosPromise<Setting.Respons> {
    return super.request(`/settings/${query.key}`, 'GET', null, query);
  }
}

export default TakeoutAPI;
