import { ITranslateSerivce } from './ITranslateService';
import { TranslateService } from './translateService';
import commonStore from '../../store/common';
import { TranslateTakeutService } from './translateTakeoutService';
import { TranslateShopService } from './translateShopService';

export class TranslateServiceBuilder {
    public static Instance(): ITranslateSerivce {
        if (commonStore.state.mode == 'remote') {
            return new TranslateService();
        }
        if (commonStore.state.mode == 'takeout') {
            return new TranslateTakeutService();
        }
        if (commonStore.state.mode == 'shop') {
            return new TranslateShopService();
        }
    }
}
