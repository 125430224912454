import sha256 from 'crypto-js/sha256';
import { IDataValidation } from './IDataValidationSerivice';

export class DataValidation implements IDataValidation {
    generateHash(data: any): string {
        const hash = sha256(JSON.stringify(data)).toString();

        return hash;
    }

    checkHash(data: any, hash: string): boolean {
        const check = this.generateHash(data);

        return check === hash;
    }
}
