import { ITranslateSerivce } from './ITranslateService';
import store from '../../store/takeout';

export class TranslateTakeutService implements ITranslateSerivce {
    _(key: string, placeholder: string = ''): string {
        const currentLangkey = store.state.langKey;
        const staticTranslates = store.state.staticTranslates[currentLangkey] || {};
        const result = staticTranslates[key];

        if (!result) {
            console.warn(`[INFO] 静的翻訳情報漏れ: ${key} ${placeholder}`);
        }

        return result || placeholder;
    }
}
