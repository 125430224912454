











import Vue from 'vue';
import store from '../../store/session';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;

export default Vue.extend({
    data() {
        return {
            translate,
        }
    },
    mounted() {
        console.log('clear');
        store.dispatch('clearSession');
        store.commit('isDone', true)
    },
});
