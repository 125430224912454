import Vuex from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import { ulid } from 'ulid';
import { ClientOrder } from '../model/entity/client_order';
import { Cart } from '../model/entity/cart';
import { Shop } from '../model/entity/shop';
import TakeoutAPI from '../common/api/api_takeout';
import { Category, Menu, MenuOption } from '@/model/menu';
import { StorageServiceBuilder } from '../services/Storage/storageServiceBuilder';
import { Customer } from '@/model/entity/customer';
import {CategoryGroup} from "@/model/entity/category_group";

Vue.use(Vuex);

const api = new TakeoutAPI();

const version = '0.003';
const storeKey = `cache.takeout-${version}`;

let categoryGroups: CategoryGroup[] = [];
let shops: Shop[] = [];
let shopClosed: string[] = [];
let cart: { shopId: string, cart: Cart }[] = [];

const takeoutStore = new Vuex.Store({
    state: {
        wsUrl: '',
        shops,
        shopClosed,
        langKey: 'ja_JP',
        staticTranslates: {} as { [key: string]: { [key: string]: string } },
        langs: [] as { name: string, locale: string, is_enable: boolean }[],

        customer: null,
        token: null,

        cart,
        categoryGroups,

        // cache
        cacheCategories: {} as { [key: string]: Category[] }, // shopid -> categories[]
        cacheCategory: {} as { [key: string]: Category },
        cacheMenu: {} as { [key: string]: Menu }, // menuid -> menu
        cacheMenuOption: {} as { [key: string]: MenuOption }, // menuoptionid -> menuoption

        // for system
        isLoading: false,
        isInitializing: false,
        isFixScroll: false,
        cartUniqKey: "",
    },

    actions: {
        load({ commit }) {
            commit('load');
        },
        init({ commit }) {
            commit('setInitializing', true);
            commit('setIsFixScroll', false);

            return api.translate({})
                .then((response) => {
                    commit('setInitializing', false);
                    if (response.data.status != 'success') {
                        throw new Error('fail to fetch static translate data');
                    }

                    commit('setLoading', false);

                    return response.data.data;
                })
                .then((data) => {
                    commit('setLangs', data.languages);
                    commit('setStaticTranslate', data.static);
                })
        },
        checkLogin() {
            const sleep = (waitSec: number): Promise<boolean> => {
                return new Promise((res) => {
                    setTimeout(() => {
                        return res(true);
                    }, waitSec * 1000);
                })
            }
            setTimeout(async () => {
                while (true) {
                    const result = await this.dispatch('_checkLogin');
                    // console.log('refresh result', result);
                    await sleep(60); // wait 1 minute

                    if (!result) {
                        break;
                    }
                }
            }, 1000);

            return this.dispatch('_checkLogin');
        },
        _checkLogin({ commit, state }) {
            if (!state.token) {
                return true;
            }
            if (!state.customer) {
                return true;
            }

            api.set_token(state.token);
            
            return api.customer_get_account()
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error();
                    }

                    return response;
                })
                .then((response) => {
                    const token = response.headers['vivo-api-token'];
                    const customer = response.data.data.customer;

                    if (!state.token) {
                        return true;
                    }

                    // check account
                    const customerId = state.customer.id;
                    if (customerId !== customer.id) {
                        return true;
                    }

                    // update token
                    commit('setToken', token);
                    commit('save');

                    return true;
                })
                .catch(() => {
                    api.set_token('');
                    commit('setCustomer', null);
                    commit('setToken', '');
                    commit('save');

                    return true;
                });
        },

        /**
         * 言語設定を初期化する
         */
        initLangKey({ commit, state }) {
            if (!state.langKey) {
                commit('setInitLangKey');
            }
        },
        selectLangKey({ commit }, langKey: string) {
            commit('setLangKey', langKey);
            commit('save');
        },

        fetchShops({ commit, state }) {
            commit('setLoading', true);

            return api.shops({ lang: state.langKey })
                .then((response) => {
                    if (response.data.status != 'success') {
                        throw new Error(response.data.message);
                    }

                    commit('setLoading', false);

                    return response.data.data;
                })
                .then((data) => {
                    commit('setShopClosed', data.shop_closed);
                    commit('setShops', data.shops);
                    commit('save');
                });
        },
        fetchCategories({ commit, state }, shopId: string) {
            commit('setLoading', true);

            return api.menu({ lang: state.langKey, shop_id: shopId, type: '2' })
                .then((response) => {
                    if (response.data.status != 'success') {
                        throw new Error(response.data.message);
                    }
                    commit('setLoading', false);

                    return response.data.data;
                })
                .then((data) => {
                    commit('setCategories', {
                        shopId,
                        categories: data.categories,
                    });
                    commit('setCategoryGroups', data.categoryGroups);
                    commit('save');
                })
        },
        fetchMenuDetail({commit, state}, id: string) {
          commit('setLoading', true);

          return api.menuDetail({id: id})
            .then((response) => {
              if (response.data.status != 'success') {
                throw new Error(response.data.message);
              }
              commit('setLoading', false);
              commit('setCacheMenu', response.data.data);
            })
        },

        login({ commit, state }, payload: { email: string, password: string }) {
            commit('setLoading', true);

            return api.customer_token(payload, { lang: state.langKey })
                .then((response) => {
                    if (response.data.status != 'success') {
                        throw new Error(response.data.message);
                    }
                    commit('setLoading', false);

                    return response.data.data;
                })
                .then((data) => {
                    commit('setCustomer', data.customer);
                    commit('setToken', data.token);
                    commit('save');
                });
        },
        logout({ commit }) {
            api.set_token('');
            commit('setCustomer', null);
            commit('setToken', '');
            commit('save');

            return Promise.resolve();
        },

        addOrder({ commit }, payload: { shopId: string, order: ClientOrder, index: number }) {
            commit('setOrder', payload);
            commit('setCartKey');
            commit('save');
        },
        removeOrder({ commit }, payload: { shopId: string, order: ClientOrder, index: number }) {
            commit('removeOrder', payload);
            commit('setCartKey');
            commit('save');
        },
        resetOrder({ commit }, payload: { shopId: string }) {
            commit('resetOrder', payload);
            commit('setCartKey');
            commit('save');
        },
        getWsUrl({ commit }) {
            api.getSignedWss().then(response => {
              if (response.data.status != 'success') {
                throw new Error('fail to get ws url');
              }
              commit('setWsUrl', response.data.data.signed_url);
            })
        }
    },

    mutations: {
        /**
           * ローカルストレージへの保存処理
           */
        save(state) {
            const target = _.cloneDeep(state);
            target.cacheMenu = {};
            target.cacheMenuOption = {};
            target.cacheCategory = {};
            target.cacheCategories = {};
            const dataStr = JSON.stringify(target);

            StorageServiceBuilder.Instance().save(storeKey, dataStr);
        },

        /**
         * ローカルストレージからの読み込み処理
         */
        load(state) {
            const dataStr = StorageServiceBuilder.Instance().load(storeKey);
            if (dataStr) {
                const store = JSON.parse(dataStr);
                store.cacheMenu = {};
                store.cacheMenuOption = {};
                store.cacheCategory = {};
                store.cacheCategories = {};

                this.replaceState(Object.assign(state, store));
            }
        },

        resetCache(state) {
            state.cacheMenu = {};
            state.cacheMenuOption = {};
            state.cacheCategory = {};
            state.cacheCategories = {};
        },

        /**
         * 表示言語キーを初期化する (日本語)
         */
        setInitLangKey(state) {
            state.langKey = 'ja_JP';
        },
        setLangKey(state, key: string) {
            state.langKey = key;
        },

        /**
         * 静的翻訳データを保持
         */
        setStaticTranslate(state, staticTranslates: { [key: string]: { [key: string]: string } }) {
            state.staticTranslates = staticTranslates;
        },
        setLangs(state, langs: { name: string, locale: string, is_enable: boolean }[]) {
            state.langs = langs;
        },

        /**
         * ログイン情報
         */
        setCustomer(state, customer: Customer) {
            state.customer = customer;
        },
        setToken(state, token: string) {
            // console.log('set token', token);
            state.token = token;
        },

        setShops(state, shops: Shop[]) {
            state.shops = shops.map(shop => {
                shop.is_closed = state.shopClosed.includes(shop.id);
                return shop;
            });
        },
        setShopClosed(state, shopClosed: string[]) {
            state.shopClosed = shopClosed;
        },
        
        setCategories(state, payload: { shopId: string, categories: Category[] }) {
            // 店舗に紐づくカテゴリのキャッシュ
            const currentCats = _.cloneDeep(state.cacheCategories);
            currentCats[payload.shopId] = payload.categories;
            state.cacheCategories = currentCats;

            // キャッシュ作成
            const currentCategory = _.cloneDeep(state.cacheCategory);
            const currentMenu = _.cloneDeep(state.cacheMenu);
            for (const cat of payload.categories) {
                currentCategory[cat.id] = cat;
                for (const menu of cat.menus) {
                    currentMenu[menu.id] = menu;
                }
            }

            state.cacheCategory = currentCategory;
            state.cacheMenu = currentMenu;
        },
        setCategoryGroups(state, data) {
            state.categoryGroups = data;
        },
        setCacheMenu(state, menu){
          state.cacheMenu[menu.id].is_set_maximum_order_per_day = menu.is_set_maximum_order_per_day;
          state.cacheMenu[menu.id].remain_order = menu.remain_order;
          state.cacheMenu[menu.id].is_soldout = menu.is_soldout;
        },
        /**
         * 商品をカートに追加
         */
        setOrder(state, payload: { order: ClientOrder, shopId: string, index: number }) {
            const targetIndex = _.findIndex(state.cart, cart => cart.shopId == payload.shopId);
            let cart: Cart;
            if (targetIndex == -1) {
                cart = new Cart();
            } else {
                cart = state.cart[targetIndex].cart;
            }

            if (payload.index == -1) {
                // new
                cart.orders.push(payload.order);
            } else {
                // update
                cart.orders[payload.index] = payload.order;
            }

            const tmp = _.cloneDeep(state.cart);
            if (targetIndex == -1) {
                tmp.push({ cart: cart, shopId: payload.shopId });
            } else {
                tmp[targetIndex].cart = cart;
            }

            state.cart = tmp;
        },
        removeOrder(state, payload: { shopId: string, index: number }) {
            const targetIndex = _.findIndex(state.cart, cart => cart.shopId == payload.shopId);
            let cart: Cart;
            if (targetIndex == -1) {
                return;
            } else {
                cart = state.cart[targetIndex].cart;
            }

            if (payload.index == -1) {
                return;
            } else {
                // remove
                cart.orders.splice(payload.index, 1);
            }

            const tmp = _.cloneDeep(state.cart);
            tmp[targetIndex].cart = cart;

            state.cart = tmp;
        },
        resetOrder(state, payload: { shopId: string }) {
            const targetIndex = _.findIndex(state.cart, cart => cart.shopId == payload.shopId);
            let cart: Cart;
            if (targetIndex == -1) {
                return;
            }

            const tmp = _.cloneDeep(state.cart);
            tmp[targetIndex].cart = new Cart();

            state.cart = tmp;
        },
        setCartKey(state) {
            state.cartUniqKey = ulid();
        },

        setLoading(state, loading) {
            state.isLoading = loading;
        },
        setInitializing(state, loading) {
            state.isInitializing = loading;
        },
        setIsFixScroll(state, isFix) {
            state.isFixScroll = isFix;
        },
        setWsUrl(state, url: string) {
            state.wsUrl = url;
        },
    },
});

export default takeoutStore;
